import { configureStore } from '@reduxjs/toolkit'

import balance from "./reducers/balance"
import buyInfo from "./reducers/buyInfo"
import sellInfo from "./reducers/sellInfo"
import menuCounts from "./reducers/menuCounts"

export default configureStore({
    reducer: {
        balance: balance,
        buyInfo: buyInfo,
        sellInfo: sellInfo,
        menuCounts: menuCounts,
    },
})