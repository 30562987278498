function request(url, options = {}, timeout = 10000) {

    if(!options.credentials){
        options.credentials = 'include';
    }

    return new Promise((resolve, reject) => {
        // Set timeout timer
        let timer = setTimeout(
            () => reject(new Error('Request timed out')),
            timeout
        );

        fetch(url, options).then(
            response => resolve(response),
            err => reject(err)
        ).finally(() => clearTimeout(timer));
    })

}

function timeAgo(date) {
    const seconds = Math.floor((new Date() - date) / 1000);
  
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
        return interval + ' years ago';
    }
  
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + ' months ago';
    }
  
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + ' days ago';
    }
  
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + ' hours ago';
    }
  
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + ' minutes ago';
    }
  
    if(seconds < 10) return 'just now';
  
    return Math.floor(seconds) + ' seconds ago';

};

function round(num, maxDecimals = 2){
    return Math.round((parseFloat(num) + Number.EPSILON) * Math.pow(10,maxDecimals)) / Math.pow(10,maxDecimals);
}

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function formatDayHourMinFromTs(ts){
    // get total seconds between the times
    var delta = ts / 1000;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    var seconds = delta % 60;

    return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    }
}

module.exports = {
    request,
    timeAgo, 
    round,
    getCookie,
    formatDayHourMinFromTs
}