import { createSlice } from '@reduxjs/toolkit'

export const balanceSlice = createSlice({
    name: 'balance',
    initialState: {
        balancePaperTrading: 0,
        balanceBinance: 0,
    },
    reducers: {
        setBalancePaperTrading: (state, action) => {
            state.balancePaperTrading = action.payload
        },

        setBalanceBinance: (state, action) => {
            state.balanceBinance = action.payload
        },
    },
})

export const { setBalancePaperTrading, setBalanceBinance } = balanceSlice.actions
export default balanceSlice.reducer