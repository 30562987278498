import React, {lazy, Suspense, useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";

import Util from "./utils/Util";
import Session from "./utils/Session";

import { useDispatch } from 'react-redux'
import * as balanceReducer from './reducers/balance';
import * as menuCountsReducer from './reducers/menuCounts';

import { Provider } from 'react-redux';
import store from './store';

import Loader from "./components/Loader/Loader"

import styles from './App.module.css';
import Dashboard from './components/Dashboard/Dashboard';

// const Home = lazy(() => import('./pages/Home/Home'));
// const Bots = lazy(() => import('./pages/Bots/Bots'));
// const BotsCreateStep1 = lazy(() => import('./pages/BotsCreateStep1/BotsCreateStep1'));
// const BotsCreateStep2 = lazy(() => import('./pages/BotsCreateStep2/BotsCreateStep2'));
// const StrategyMarket = lazy(() => import('./pages/StrategyMarket/StrategyMarket'));
// const Strategies = lazy(() => import('./pages/Strategies/Strategies'));
// const SmartTrade = lazy(() => import('./pages/SmartTrade/SmartTrade'));
// const Settings = lazy(() => import('./pages/Settings/Settings'));
// const Login = lazy(() => import('./pages/Login/Login'));
// const Logout = lazy(() => import('./pages/Logout/Logout'));
// const NotFound = lazy(() => import('./pages/NotFound/NotFound'));

const lazyDelay = 500;

const Home = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/Home/Home');});
const Bots = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/Bots/Bots');});
const BotsCreateStep1 = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/BotsCreateStep1/BotsCreateStep1');});
const BotsCreateStep2 = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/BotsCreateStep2/BotsCreateStep2');});
const StrategyMarket = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/StrategyMarket/StrategyMarket');});
const Strategies = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/Strategies/Strategies');});
const SmartTrade = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/SmartTrade/SmartTrade');});
const Settings = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/Settings/Settings');});
const Login = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/Login/Login');});
const Logout = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/Logout/Logout');});
const NotFound = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/NotFound/NotFound');});

export default function App() {

    useEffect(()=>{

        return ()=>{
            // console.log("unmount");
        }
    }, []);

    return (
        
        <Provider store={store}>

            <div className={styles.app}>

                <Start>

                    <Suspense fallback={<Loader />}>
                        <BrowserRouter basename='/'>
                            <Routes>

                                <Route path="/login" element={<Login />} />
                                <Route path="/logout" element={<PrivateRoute><Logout /></PrivateRoute>} />

                                <Route path="/" element={<Dashboard />}>
                                    <Route path="/" element={<Navigate to="/home" replace />} />
                                    <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
                                    <Route path="/my-strategies" element={<PrivateRoute><Strategies /></PrivateRoute>} />
                                    <Route path="/my-bots" element={<PrivateRoute><Bots /></PrivateRoute>} />
                                    <Route path="/my-bots/create/step-1" element={<PrivateRoute><BotsCreateStep1 /></PrivateRoute>} />
                                    <Route path="/my-bots/create/step-2" element={<PrivateRoute><BotsCreateStep2 /></PrivateRoute>} />

                                    <Route path="/my-fav-strategies" element={<PrivateRoute><NotFound /></PrivateRoute>} />
                                    <Route path="/my-fav-bots" element={<PrivateRoute><NotFound /></PrivateRoute>} />

                                    <Route path="/strategy-market" element={<PrivateRoute><StrategyMarket /></PrivateRoute>} />
                                    <Route path="/smart-trade" element={<PrivateRoute><SmartTrade /></PrivateRoute>} />
                                    <Route path="/activity-log" element={<PrivateRoute><NotFound /></PrivateRoute>} />

                                    <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                                </Route>
                                
                                <Route path="*" element={<NotFound />} />

                            </Routes>
                        </BrowserRouter>
                    </Suspense>

                </Start>

            </div>

        </Provider>
        
    );
}

function Start(props){
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(()=>{

        let url = "/api/start";
        let options = {
            method: 'POST',
        }

        Util.request(url, options, 10000)
            .then((response) => response.json())
            .then((json) => {    

                if(json.status === "ok"){
                    if(json.data.user !== null){
                        //update users store
    
                        dispatch(balanceReducer.setBalanceBinance(0));
                        dispatch(balanceReducer.setBalancePaperTrading(0));
                        dispatch(menuCountsReducer.setBotsCount(json.data.bots_count));
                    }
                }
                
            })
            .catch((error) => {
                // console.log(error);
            })
            .finally(()=>{
                setLoading(false);
            });

        return ()=>{
            // console.log("unmount");
        }
    }, []);

    if(loading){
        return <Loader />
    }

    return props.children;
}

function PrivateRoute(props){
    const location = useLocation();
  
    if (!Session.isSignedIn()) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }
  
    return props.children;
}
