import {Suspense} from 'react';
import { useSelector } from 'react-redux'
import { Link, useLocation, Outlet } from "react-router-dom";

import ScrollArea from "../ScrollArea/ScrollArea";
import Loader from "../Loader/Loader"

import {ReactComponent as IcoLogo} from "../../icons/logo.svg";

import {ReactComponent as IcoHome} from "../../icons/home.svg";
import {ReactComponent as IcoBot} from "../../icons/bot.svg";
import {ReactComponent as IcoFlow} from "../../icons/flow.svg";
import {ReactComponent as IcoCandles} from "../../icons/candles.svg";
import {ReactComponent as IcoTimeBack} from "../../icons/time-back.svg";
import {ReactComponent as IcoCart} from "../../icons/cart.svg";
import {ReactComponent as IcoStar} from "../../icons/star2.svg";

import ImgAvatar from "../../icons/avatar.png";

import {ReactComponent as SvgPlus} from "../../icons/plus.svg";
import {ReactComponent as SvgBell} from "../../icons/bell.svg";
import {ReactComponent as SvgArrowDown} from "../../icons/arrow-down.svg";

import styles from "./Dashboard.module.css";

export default function Dashboard(props){
    return (
        <div className={styles.dashboard}>
            <LeftMenu />
            <Header />

            <TeamsMenu />
            
            <div className={styles.content}>
                <ScrollArea>
                    <div className={styles.page}>

                        {/* {props.children} */}
                        <Suspense fallback={<div className={styles.suspenseLoader}><Loader /></div>}>
                            <Outlet />
                        </Suspense>
                        
                    </div>
                </ScrollArea>
            </div>
        </div>
    );
}

function Header(){
    const location = useLocation();

    const balancePaperTrading = useSelector((state) => state.balance.balancePaperTrading);
    const balanceBinance = useSelector((state) => state.balance.balanceBinance);

    // useEffect(()=>{
    //     return ()=>{
    //         // console.log("unmount");
    //     }
    // }, []);

    let activePage = "Unknown";
    let breadcrumbsArr = [];

    if(location.pathname === "/" || location.pathname.startsWith("/home")){
        activePage = "Home";
        breadcrumbsArr.push(
            <Link to="/home" key={Math.random()} className={styles.link}>{activePage}</Link>
        );
    }

    if(location.pathname.startsWith("/my-bots")){
        activePage = "My Bots";
        breadcrumbsArr.push(
            <Link to="/my-bots" key={Math.random()} className={styles.link}>{activePage}</Link>
        );
    }

    if(location.pathname.startsWith("/my-bots/create/step-1") || location.pathname.startsWith("/my-bots/create/step-2")){
        activePage = "Create";
        breadcrumbsArr.push(
            <span key={Math.random()} className={styles.arrow}>&gt;</span>
        );
        breadcrumbsArr.push(
            <Link to="/my-bots/create/step-1" key={Math.random()} className={styles.link}>{activePage}</Link>
        );
    }

    if(location.pathname.startsWith("/my-bots/create/step-2")){
        let botType = "";
        if(location.state){
            if(location.state.botType){
                botType = location.state.botType;
            }
        }
        activePage = botType;
        breadcrumbsArr.push(
            <span key={Math.random()} className={styles.arrow}>&gt;</span>
        );
        breadcrumbsArr.push(
            <Link to="/my-bots/create/step-2" key={Math.random()} state={location.state} className={styles.link}>{activePage}</Link>
        );
    }

    if(location.pathname.startsWith("/strategy-market")){
        activePage = "Strategy market";
        breadcrumbsArr.push(
            <Link to="/strategy-market" key={Math.random()} className={styles.link}>{activePage}</Link>
        );
    }

    if(location.pathname.startsWith("/my-strategies")){
        activePage = "My strategies";
        breadcrumbsArr.push(
            <Link to="/my-strategies" key={Math.random()} className={styles.link}>{activePage}</Link>
        );
    }

    if(location.pathname.startsWith("/smart-trade")){
        activePage = "Smart trade";
        breadcrumbsArr.push(
            <Link to="/smart-trade" key={Math.random()} className={styles.link}>{activePage}</Link>
        );
    }

    if(location.pathname.startsWith("/settings")){
        activePage = "Settings";
        breadcrumbsArr.push(
            <Link to="/settings" key={Math.random()} className={styles.link}>{activePage}</Link>
        );
    }

    document.title = activePage;

    return (
        <div className={styles.header}>
            <div className={styles.title}>WIN1</div>
            {/* <div className={styles.breadcrumb}>{breadcrumbsArr}</div> */}
            
{/* 
            <div className={styles.balance}>
                <div className={styles.txt1}>Paper Trading Balance</div>
                <div className={styles.txt2}>{balancePaperTrading.toFixed(2)} USDT</div>
            </div> */}

            <div className={styles.balance}>
                <div className={styles.txt1}>Binance Balance<SvgArrowDown /></div>
                <div className={styles.txt2}>{balanceBinance.toFixed(2)} USDT</div>
            </div>

            <div className={styles.upgrade}>Upgrade</div>
            <div className={styles.notifications}><SvgBell /></div>
                
            <div className={styles.user}>
                <div className={styles.avatar}>
                    <img 
                        onError={(e)=>{ 
                            if (e.target.src !== ImgAvatar){
                                e.target.src = ImgAvatar;
                            }
                        }}
                        src={"empty"} 
                        alt="Avatar" />
                </div>
                <SvgArrowDown />
            </div>
        </div>
    );
}

function LeftMenu(){
    const location = useLocation();

    const botsCount = useSelector((state) => state.menuCounts.botsCount);

    let itemHomeActive = "";
    if(location.pathname.startsWith("/home")){
        itemHomeActive = styles.active;
    }

    let itemMyStrategiesActive = "";
    if(location.pathname.startsWith("/my-strategies")){
        itemMyStrategiesActive = styles.active;
    }

    let itemMyBotsActive = "";
    if(location.pathname.startsWith("/my-bots")){
        itemMyBotsActive = styles.active;
    }




    let itemMyFavStrategiesActive = "";
    if(location.pathname.startsWith("/my-fav-strategies")){
        itemMyFavStrategiesActive = styles.active;
    }

    let itemMyFavBotsActive = "";
    if(location.pathname.startsWith("/my-fav-bots")){
        itemMyFavBotsActive = styles.active;
    }



    let itemStrategyMarketActive = "";
    if(location.pathname.startsWith("/strategy-market")){
        itemStrategyMarketActive = styles.active;
    }

    let itemSmartTradeActive = "";
    if(location.pathname.startsWith("/smart-trade")){
        itemSmartTradeActive = styles.active;
    }

    let itemActivityLogActive = "";
    if(location.pathname.startsWith("/activity-log")){
        itemActivityLogActive = styles.active;
    }

    return (
        
        <div className={styles.leftMenu}>

            <ScrollArea>

                <div className={styles.leftMenuContent}>
            
                    {/* <div className={styles.logo}>
                        <IcoLogo />
                        <div className={styles.txt}>W<span className={styles.low}>in</span>1</div>
                    </div> */}

                    <div className={styles.team}>
                        <div className={styles.name}>
                            <SvgArrowDown /><span>Iosif’s team</span>
                        </div>
                        <span className={styles.members}>1 user</span>
                        <span className={styles.invite}>+ Invite mambers</span>
                    </div>


                    <div className={styles.menuItemsTop}>

                        <Link to="/home" className={styles.item+" "+itemHomeActive} style={{marginTop: 0}}>
                            <div className={styles.icon}><IcoHome /></div>
                            <div className={styles.txt}>Performance</div>
                        </Link>

                        <Link to="/my-strategies" className={styles.item+" "+itemMyStrategiesActive}>
                            <div className={styles.icon}><IcoFlow /></div>
                            <div className={styles.txt}>Strategies (0)</div>
                        </Link>

                        <div className={styles.itemContainer}>
                            <Link to="/my-bots" className={styles.item+" "+itemMyBotsActive}>
                                <div className={styles.icon}><IcoBot /></div>
                                <div className={styles.txt}>Bots ({botsCount})</div>
                            </Link>
                            <Link to="/my-bots/create/step-1" className={styles.quick}>
                                <SvgPlus />
                            </Link>
                        </div>


                        <div className={styles.line}></div>


                        <Link to="/my-fav-strategies" className={styles.item+" "+itemMyFavStrategiesActive} style={{marginTop: 0}}>
                            <div className={styles.icon}><IcoStar /></div>
                            <div className={styles.txt}>Starred strategies</div>
                        </Link>

                        <Link to="/my-fav-bots" className={styles.item+" "+itemMyFavBotsActive}>
                            <div className={styles.icon}><IcoStar /></div>
                            <div className={styles.txt}>Starred bots</div>
                        </Link>


                        <div className={styles.line}></div>

                        
                        <Link to="/strategy-market" className={styles.item+" "+itemStrategyMarketActive} style={{marginTop: 0}}>
                            <div className={styles.icon}><IcoCart /></div>
                            <div className={styles.txt}>Strategy market</div>
                        </Link>

                        <Link to="/smart-trade" className={styles.item+" "+itemSmartTradeActive}>
                            <div className={styles.icon}><IcoCandles /></div>
                            <div className={styles.txt}>Smart trade</div>
                        </Link>

                        <Link to="/activity-log" className={styles.item+" "+itemActivityLogActive}>
                            <div className={styles.icon}><IcoTimeBack /></div>
                            <div className={styles.txt}>Activity log</div>
                        </Link>

                    </div>

                    {/* <div className={styles.user}>
                        <div className={styles.avatar}>
                            <img 
                                onError={(e)=>{ 
                                    if (e.target.src !== ImgAvatar){
                                        e.target.src = ImgAvatar;
                                    }
                                }}
                                src={"empty"} 
                                alt="Avatar" />
                        </div>
                        <div className={styles.text}>
                            <div className={styles.txt1}>Hello</div>
                            <div className={styles.txt2}>Usename</div>
                        </div>
                    </div> */}


                    <div className={styles.menuItemsBottom}>

                        <Link to="/settings" className={styles.item}>
                            <div className={styles.txt}>Settings</div>
                        </Link>

                        <Link to="/logout" className={styles.item}>
                            <div className={styles.txt}>Logout</div>
                        </Link>

                    </div>

                    <div className={styles.copyright}>
                        All rights reserved.<br/>
                        Copyright Win1 fortune s.r.l..
                    </div>

                </div>

            </ScrollArea>

        </div>
        
    );
}

function TeamsMenu(){
    return (
        
        <div className={styles.teamsMenu}>

            <ScrollArea>

                <div className={styles.teamsMenuContent}>
            
                    <div className={styles.team+" "+styles.active}>IO</div>
                    <div className={styles.team} style={{background:"#289BCF"}}>Mir <span className={styles.notifCount}>1</span></div>
                    <div className={styles.team} style={{background:"#E7923A"}}>Pro</div>

                    <div className={styles.add}><SvgPlus /></div>

                </div>

            </ScrollArea>

        </div>
        
    );
}
