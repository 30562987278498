import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
// import { Scrollbars } from 'react-custom-scrollbars';
import { Scrollbars } from 'react-custom-scrollbars-2';

import styles from './ScrollArea.module.css';

ScrollArea.defaultProps = {
    universal: true,

    autoHide: true,
    autoHideTimeout: 1000,
};

ScrollArea.propTypes = {
    universal: PropTypes.bool,

    autoHide: PropTypes.bool,
    autoHideTimeout: PropTypes.number,
}

function ScrollArea(props) {

    const [thumbShow, setThumbShow] = useState(false);

    const autoHideTimer = useRef(null);
    const scrollAreaEnter = useRef(false);
    const scrollBarEnter = useRef(false);
    const scrollBarPointerDown = useRef(false);

    useEffect(()=>{
        window.addEventListener('pointerup', onScrollBarPointerUp);

        return ()=>{
            // console.log("unmount");
            window.removeEventListener('pointerup', onScrollBarPointerUp);
        }
    }, []);

    function showScrollBar(){
        setThumbShow(true);
    }

    function hideScrollBar(){
        if(scrollBarEnter.current){
            return;
        }

        if(scrollBarPointerDown.current){
            return;
        }

        setThumbShow(false);
    }

    //scroll area
    function onScrollAreaEnter(e){
        scrollAreaEnter.current = true;

        showScrollBar();
        startAutoHideTimer();
        // e.stopPropagation();
    }

    function onScrollAreaLeave(e){
        scrollAreaEnter.current = false;

        hideScrollBar();
    }

    function onScrollStart(){
        showScrollBar();
        startAutoHideTimer();
    }

    //scroll bar
    function onScrollBarEnter(){
        scrollBarEnter.current = true;

        showScrollBar();
    }

    function onScrollBarLeave(){
        scrollBarEnter.current = false;

        if(scrollAreaEnter.current){
            showScrollBar();
            startAutoHideTimer();
        }else{
            hideScrollBar();
        }
    }

    function onScrollBarPointerDown(){
        scrollBarPointerDown.current = true;

        showScrollBar();
    }

    function onScrollBarPointerUp(){
        scrollBarPointerDown.current = false;

        hideScrollBar();
    }

    //start stop timers
    function startAutoHideTimer(){
        if(!props.autoHide){
            return;
        }

        stopAutoHideTimer();

        autoHideTimer.current = setTimeout(()=>{
            autoHideTimer.current = null;
            hideScrollBar();
        }, props.autoHideTimeout);
    }

    function stopAutoHideTimer(){
        if(!props.autoHide){
            return;
        }

        if(autoHideTimer.current != null){
            clearTimeout(autoHideTimer.current);
            autoHideTimer.current = null;
        }
    }

    let newStyles = {...props.style} || {};

    let thumbVerticalShow = "";
    if(thumbShow){
        thumbVerticalShow = styles.show;
    }

    return (
        <Scrollbars 
            onPointerEnter={onScrollAreaEnter}
            onPointerLeave={onScrollAreaLeave}
            onScrollStart={onScrollStart}

            className={styles.scrollArea+" "+(props.className || "")} 
            style={newStyles}

            renderTrackVertical={({ style, ...props }) => (
                <div {...props} className={styles.scrollTrackVertical} 
                    onPointerDown={onScrollBarPointerDown}
                    // onPointerUp={onScrollBarPointerUp}
                    onPointerEnter={onScrollBarEnter} 
                    onPointerLeave={onScrollBarLeave}></div>
            )}

            renderThumbVertical={({ style, ...props }) => (
                <div {...props} className={styles.scrollThumbVertical+" "+thumbVerticalShow}></div>
            )}

            universal={props.universal}
        >

            {props.children}
        </Scrollbars>
       
    );
}


export default ScrollArea;