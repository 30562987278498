import { createSlice } from '@reduxjs/toolkit'

export const sellInfoSlice = createSlice({
    name: 'sellInfo',
    initialState: {
        takeProfit: 0,
        trailingDeviation: 0,
    },
    reducers: {
        setTakeProfit: (state, action) => {
            state.takeProfit = action.payload
        },

        setTrailingDeviation: (state, action) => {
            state.trailingDeviation = action.payload
        },
    },
})

export const { setTakeProfit, setTrailingDeviation } = sellInfoSlice.actions
export default sellInfoSlice.reducer