function signIn(){

}

function signOut(){

}

function isSignedIn(){
    if(document.cookie && document.cookie !== ""){
        if(document.cookie.indexOf("session_active=1") !== -1){
            return true;
        }
    }

    return false;
}

module.exports = {
    signIn,
    signOut, 
    isSignedIn
}