import { createSlice } from '@reduxjs/toolkit'

export const buyInfoSlice = createSlice({
    name: 'buyInfo',
    initialState: {
        maxSafetyOrderCover: 0,
        totalInvest: 0,
    },
    reducers: {
        setMaxSafetyOrderCover: (state, action) => {
            state.maxSafetyOrderCover = action.payload
        },

        setTotalInvest: (state, action) => {
            state.totalInvest = action.payload
        },
    },
})

export const { setMaxSafetyOrderCover, setTotalInvest } = buyInfoSlice.actions
export default buyInfoSlice.reducer