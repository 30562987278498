import { createSlice } from '@reduxjs/toolkit'

export const menuCountsSlice = createSlice({
    name: 'menuCounts',
    initialState: {
        botsCount: 0,
    },
    reducers: {
        setBotsCount: (state, action) => {
            state.botsCount = action.payload
        },
    },
})

export const { setBotsCount } = menuCountsSlice.actions
export default menuCountsSlice.reducer